import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import Swal from 'sweetalert2';

import './styles/globals.css';

import {Switch} from "@/components/ui/switch";
import {Label} from "@/components/ui/label";
import {Textarea} from "@/components/ui/textarea";
import {Button} from "@/components/ui/button";
import {Input} from "@/components/ui/input";
import { Badge } from "@/components/ui/badge";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
} from "@/components/ui/table";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog";
import {
    BellIcon,
    Bot,
    ChevronDownIcon,
    Plus,
    Search,
    Wallet,
    X
} from "lucide-react";
import {BrowserView, MobileView} from 'react-device-detect';

export const _API_URL = 'https://auto-api.sp-evogames.com';
export const _API_KEY = localStorage.getItem('token');

export const _THEME: 'white' | 'black' = (localStorage.getItem('theme') as 'white' | 'black') ?? 'black';

const PageHeader = styled.header`
  background-color: #282c34;
  min-height: 50px;
`;
const RedItem = styled.div<{ useDelete?: boolean }>`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  line-height: 28px;
  text-align: center;
  background-color: #e84c3d;
  color: white;
  display: inline-block;
  margin-right: 3px;
  cursor: pointer;
  font-size: 12px;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;

  ${props => props.useDelete && `
      &:hover::before {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJsdWNpZGUgbHVjaWRlLXgiPjxwYXRoIGQ9Ik0xOCA2IDYgMTgiLz48cGF0aCBkPSJtNiA2IDEyIDEyIi8+PC9zdmc+);
        background-size: cover;
        content: '';
        width: 14px;
        height: 14px;
        position: absolute;
        border: 1px solid black;
        background-color: white;
        border-radius: 50%;
        margin-left: 9px;
        margin-top: -2px;
      }
  `}
`;
const BlueItem = styled.div<{ useDelete?: boolean }>`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  line-height: 28px;
  text-align: center;
  background-color: #3498db;
  color: white;
  display: inline-block;
  margin-right: 3px;
  cursor: pointer;
  font-size: 12px;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;

  ${props => props.useDelete && `
      &:hover::before {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJsdWNpZGUgbHVjaWRlLXgiPjxwYXRoIGQ9Ik0xOCA2IDYgMTgiLz48cGF0aCBkPSJtNiA2IDEyIDEyIi8+PC9zdmc+);
        background-size: cover;
        content: '';
        width: 14px;
        height: 14px;
        position: absolute;
        border: 1px solid black;
        background-color: white;
        border-radius: 50%;
        margin-left: 9px;
        margin-top: -2px;
      }
  `}
`;

const _GAMES: Record<string, string> = {
    POWERBALL_UNOVER: '파워볼 언오버',
    POWERBALL_ODDEVEN: '파워볼 홀짝',
    REGULAR_UNOVER: '일반볼 언오버',
    REGULAR_ODDEVEN: '일반볼 홀짝',
};

function formatDate(date: Date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');
    const second = date.getSeconds().toString().padStart(2, '0');
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}

function useInterval(callback: Function, delay: number) {
    const savedCallback = useRef<Function>();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            if (savedCallback.current) {
                savedCallback.current();
            }
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

function App() {
    const [currentGameRound, setCurrentGameRound] = useState<number>(-1);
    const [nextGameTime, setNextGameTime] = useState<Date>(new Date());
    const [gameLeftTime, setGameLeftTime] = useState<number>(-1);
    const [isSearchDialogOpen, setIsSearchDialogOpen] = useState<boolean>(false);
    const [mode, setMode] = useState<'BEGINNER' | 'ADVANCED'>('BEGINNER');
    const [selectedGameType, setSelectedGameType] = useState<string>('');
    const [balance, setBalance] = useState<number>(0);
    const [limits, setLimits] = useState<{
        winLimit?: number;
        loseLimit?: number;
    }>({});
    const [betAmounts, setBetAmounts] = useState<{
        winAmount: number;
        loseAmount: number;
    }>({
        winAmount: 0,
        loseAmount: 0,
    });
    const [statistics, setStatistics] = useState<{
        betAmountSum: number;
        winAmountSum: number;
        betCount: number;
    }>({
        betAmountSum: 0,
        winAmountSum: 0,
        betCount: 0,
    });
    const [autoEnabled, setAutoEnabled] = useState<boolean>(false);
    const [patterns, setPatterns] = useState<Array<{
        id: number;
        game: {
            id: string;
            items: string[];
        };
        betColumn: number;
        betRow: number;
        pendingPattern: string;
        pattern: string;
        recentBet: {
            roundId: number;
            game: string;
            item: string;
            amount: number;
            winAmount: number;
            result: number;
            created: string;
        }[];
    }>>([
        {
            id: -1,
            game: {
                id: 'POWERBALL_ODD',
                items: [],
            },
            pendingPattern: '-',
            betColumn: 0,
            betRow: 0,
            pattern: '',
            recentBet: [],
        }
    ]);
    const [betHistory, setBetHistory] = useState<{
        roundId: number;
        game: string;
        item: string;
        amount: number;
        winAmount: number;
        result: number;
        created: string;
    }[]>()

    const [searchPatternForm, setSearchPatternForm] = useState<{
        gameType: string,
        pattern: string,
        period: string,
    }>({
        gameType: '',
        pattern: '',
        period: '',
    });
    const [searchPatternResult, setSearchPatternResult] = useState<{
        targetGameCount: number;
        winCount: number;
        loseCount: number;
        betSum: number;
        winSum: number;
        minBalance: number;
        maxBalance: number;
        currentBalance: number;
    } | undefined>(undefined);

    const auth = async () => {
        const request = await fetch(`${_API_URL}/user/auth?userToken=${_API_KEY}`);
        const response = await request.json();

        if (response.success) {
            return true;
        } else {
            throw new Error('인증에 실패했습니다.');
        }
    }

    const getHistory = async () => {
        const request = await fetch(`${_API_URL}/bet/history?userToken=${_API_KEY}`);
        const response = await request.json();

        if (response.success) {
            setBetHistory(response.bets);
        } else {
            Swal.fire({
                title: '오류',
                text: '베팅 기록을 불러오는 중 오류가 발생했습니다.',
                icon: 'error',
            });
        }
    }

    const getPattern = async (updateOnlyTarget: boolean) => {
        const request = await fetch(`${_API_URL}/pattern?userToken=${_API_KEY}`);
        const response = await request.json();

        if (response.success) {
            if (updateOnlyTarget) {
                setPatterns((curr) => {
                    const newPatterns = JSON.parse(JSON.stringify(curr));
                    for (let i = 0; i < response.patterns.length; i++) {
                        const pattern = response.patterns[i];
                        const p = newPatterns.find((pattern: Record<string, unknown>) => response.patterns[i].id === pattern.id);
                        if (p) {
                            p.betRow = pattern.betRow;
                            p.betColumn = pattern.betColumn;
                            p.recentBet = pattern.recentBet;
                        }
                    }
                    return newPatterns;
                });
            } else {
                setPatterns([
                    ...response.patterns.map((pattern: Record<string, string>) => ({
                        ...pattern,
                        pendingPattern: '-',
                    })),
                    {
                        id: -1,
                        game: {
                            id: 'POWERBALL_ODD',
                            items: [],
                        },
                        pendingPattern: '-',
                        pattern: '',
                    }
                ]);
            }
        } else {
            Swal.fire({
                title: '오류',
                text: '패턴 정보를 불러오는 중 오류가 발생했습니다.',
                icon: 'error',
            });
        }
    }
    const getAutoEnabled = async (updateLimits = true) => {
        const request = await fetch(`${_API_URL}/user/balance?userToken=${_API_KEY}`);
        const response = await request.json();

        if (response.success) {
            setBalance(response.balance);
            setAutoEnabled(response.useAuto);
            if (updateLimits) {
                setLimits({
                    winLimit: response.winLimit,
                    loseLimit: response.loseLimit,
                });
            }
            setBetAmounts({
                winAmount: response.winAmount,
                loseAmount: response.loseAmount,
            });
        } else {
            Swal.fire({
                title: '오류',
                text: '사용자 정보를 불러오는 중 오류가 발생했습니다.',
                icon: 'error',
            });
        }
    }
    const getStatistics = async () => {
        const date = new Date();
        const convertedDate = `${date.getFullYear()}${(date.getMonth() + 1).toString().padStart(2, '0')}${date.getDate().toString().padStart(2, '0')}`;
        const request = await fetch(`${_API_URL}/bet/statistics?userToken=${_API_KEY}&day=${convertedDate}`);
        const response = await request.json();

        if (response.success) {
            setStatistics(response.statistics);
        } else {
            Swal.fire({
                title: '오류',
                text: '통계 정보를 불러오는 중 오류가 발생했습니다.',
                icon: 'error',
            });
        }
    }
    const updateAutoEnabled = async (enabled: boolean) => {
        setAutoEnabled(enabled);

        const request = await fetch(`${_API_URL}/user/enable`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                userToken: _API_KEY,
                autoEnabled: enabled,
            }),
        });
        const response = await request.json();

        if (!response.success) {
            throw new Error('오토 사용 여부를 변경하는 중 오류가 발생했습니다.');
        }
        if (enabled) {
            Swal.fire({
                title: '알림',
                text: '오토 동작을 시작합니다.',
                icon: 'success',
            });
        }
    }
    const addPattern = async (gameId: string, patternString: string, ignoreErrors = false) => {
        if (!ignoreErrors && patternString.trim() === '') {
            Swal.fire({
                title: '오류',
                text: '패턴을 하나 이상 입력해 주세요.',
                icon: 'error',
            });
            throw new Error('패턴을 하나 이상 입력해 주세요.');
        } else if (!ignoreErrors && patternString.split('\n')[0].split(':').length < 2) {
            Swal.fire({
                title: '오류',
                text: '패턴이 모두 입력되지 않았습니다.',
                icon: 'error',
            });
            throw new Error('패턴이 모두 입력되지 않았습니다.');
        } else if (!ignoreErrors && gameId === '') {
            Swal.fire({
                title: '오류',
                text: '게임 종류가 선택되지 않았습니다.',
                icon: 'error',
            });
            throw new Error('게임 종류가 선택되지 않았습니다.');
        }

        const request = await fetch(`${_API_URL}/pattern`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                userToken: _API_KEY,
                gameId,
                pattern: patternString,
            }),
        });
        const response = await request.json();

        if (!response.success) {
            Swal.fire({
                title: '오류',
                text: '패턴을 추가하는 중 오류가 발생했습니다.',
                icon: 'error',
            });
            throw new Error('패턴을 추가하는 중 오류가 발생했습니다.');
        }

        await getPattern(false);
        if (!ignoreErrors) {
            Swal.fire({
                title: '패턴 추가 완료',
                text: '패턴이 추가되었습니다.',
                icon: 'success',
            });
        }
    }
    const deletePattern = async (patternId: number) => {
        const request = await fetch(`${_API_URL}/pattern/${patternId}?userToken=${_API_KEY}`, {
            method: 'DELETE',
        });
        const response = await request.json();

        if (response.success) {
            await getPattern(false);
            Swal.fire({
                title: '패턴 삭제 완료',
                text: '패턴이 삭제되었습니다.',
                icon: 'success',
            });
        } else {
            Swal.fire({
                title: '오류',
                text: '패턴을 삭제하는 중 오류가 발생했습니다.',
                icon: 'error',
            });
            throw new Error('패턴을 삭제하는 중 오류가 발생했습니다.');
        }
    }
    const editPattern = async (patternId: number, patternString: string) => {
        if (patternString.trim() === '') {
            Swal.fire({
                title: '오류',
                text: '패턴을 하나 이상 입력해 주세요.',
                icon: 'error',
            });
            throw new Error('패턴을 하나 이상 입력해 주세요.');
        } else if (patternString.split('\n')[0].split(':').length < 2) {
            Swal.fire({
                title: '오류',
                text: '패턴이 모두 입력되지 않았습니다.',
                icon: 'error',
            });
            throw new Error('패턴이 모두 입력되지 않았습니다.');
        }

        const request = await fetch(`${_API_URL}/pattern/${patternId}`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                userToken: _API_KEY,
                pattern: patternString,
            }),
        });
        const response = await request.json();

        if (!response.success) {
            Swal.fire({
                title: '오류',
                text: '패턴을 수정하는 중 오류가 발생했습니다.',
                icon: 'error',
            });
            throw new Error('패턴을 수정하는 중 오류가 발생했습니다.');
        }

        Swal.fire({
            title: '패턴 변경 완료',
            text: '패턴이 수정되었습니다.',
            icon: 'success',
        });
    }

    const updateLimits = async (winLimit: number, loseLimit: number) => {
        const request = await fetch(`${_API_URL}/user/limit`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                userToken: _API_KEY,
                winLimit,
                loseLimit,
            }),
        });
        const response = await request.json();

        if (response.success) {
            Swal.fire({
                title: '한도 변경 완료',
                text: '손실/수익 한도가 수정되었습니다.',
                icon: 'success',
            });
        } else {
            Swal.fire({
                title: '한도 변경 실패',
                text: '손실/수익 한도를 수정하는 중 오류가 발생했습니다.',
                icon: 'error',
            });
            throw new Error('손실/수익 한도를 변경하는 중 오류가 발생했습니다.');
        }
    }

    const updateBetAmount = (patternId: number, lineNumber: number, betAmount: string) => {
        const lines = patterns.find((pattern) => pattern.id === patternId)?.pattern.split('\n').map((line) => line.split(':')) ?? [];

        if (lines.length - 1 < lineNumber) {
            for (let i = 0; i <= lineNumber - lines.length + 1; i++) {
                if (lines.length === 0) lines.push(['0']);
                else lines.push([...lines[0]]);
            }
        }
        if (betAmount === '') betAmount = '0';
        lines[lineNumber][0] = betAmount;

        const newPattern = lines.map((line) => line.join(':')).join('\n');
        setPatterns(patterns.map((pattern) => pattern.id === patternId ? {
            ...pattern,
            pattern: newPattern,
        } : pattern));
    }

    const removePatternRow = (patternId: number, row: number) => {
        const lines = patterns.find((pattern) => pattern.id === patternId)?.pattern.split('\n').map((line) => line.split(':')) ?? [];
        for (let i = 0; i < lines.length; i++) {
            lines[i].splice(row + 1, 1);
        }

        const newPattern = lines.map((line) => line.join(':')).join('\n');
        setPatterns(patterns.map((pattern) => pattern.id === patternId ? {
            ...pattern,
            pattern: newPattern,
        } : pattern));
    }

    const addPendingItem = (patternId: number, item: string) => {
        const newPattern = JSON.parse(JSON.stringify(patterns));
        const p = newPattern.find((pattern: Record<string, unknown>) => pattern.id === patternId);

        p.pendingPattern = p.pendingPattern.split('-')[0] + item + '-' + p.pendingPattern.split('-')[1];
        setPatterns(newPattern);
    }
    const removePendingItem = (patternId: number, index: number) => {
        const newPattern = JSON.parse(JSON.stringify(patterns));
        const p = newPattern.find((pattern: Record<string, unknown>) => pattern.id === patternId)
        p.pendingPattern = p.pendingPattern.split('');
        p.pendingPattern.splice(index, 1);
        p.pendingPattern = p.pendingPattern.join('');
        setPatterns(newPattern);
    }

    const setPendingTarget = (patternId: number, target: string) => {
        const newPattern = JSON.parse(JSON.stringify(patterns));
        const p = newPattern.find((pattern: Record<string, unknown>) => pattern.id === patternId)
        p.pendingPattern = p.pendingPattern.split('-')[0] + '-' + target;
        setPatterns(newPattern);
    }

    const addPending = (patternId: number) => {
        const newPattern = JSON.parse(JSON.stringify(patterns));
        const p = newPattern.find((pattern: Record<string, unknown>) => pattern.id === patternId);
        if (p.pendingPattern.split('-')[0].length === 0) {
            Swal.fire({
                title: '오류',
                text: '찾을 패턴을 선택해 주세요.',
                icon: 'error',
            });
            return;
        } else if (p.pendingPattern.split('-')[1].length === 0) {
            Swal.fire({
                title: '오류',
                text: '베팅할 항목을 선택해 주세요.',
                icon: 'error',
            });
            return;
        }

        const lines = p.pattern.split('\n');
        for (let i = 0; i < lines.length; i++) {
            lines[i] += ':' + p.pendingPattern;
        }
        p.pattern = lines.join('\n');
        p.pendingPattern = '-';

        setPatterns(newPattern);
    }

    useEffect(() => {
        auth().then(() => {
            getPattern(false).then();
            getAutoEnabled().then();
            getStatistics().then();
            getHistory().then();
        }).catch(() => {
            Swal.fire({
                title: '인증 오류',
                text: '인증에 실패했습니다. 이 창을 닫고 다시 열어볼 수 있습니다.\n문제가 계속되는 경우 사이트 관리자에게 문의하세요.',
                icon: 'error',
                showConfirmButton: false,
                showCancelButton: false,
                allowOutsideClick: false,
            });
        });
    }, []);

    const [telegramToken, setTelegramToken] = useState<string>('');
    const [telegramSettings, setTelegramSettings] = useState<{
        doUseStopAlert: boolean;
        doUseBetAlert: boolean;
        doUseResultAlert: boolean;
    }>({
        doUseStopAlert: false,
        doUseBetAlert: false,
        doUseResultAlert: false,
    });
    const loadTelegramSettings = async () => {
        const request = await fetch(`${_API_URL}/user/telegram?userToken=${_API_KEY}`);
        const response = await request.json();

        if (response.success) {
            setTelegramToken(response.token);
            setTelegramSettings(response.settings);
        } else {
            Swal.fire({
                title: '오류',
                text: '텔레그램 설정을 불러오는 중 오류가 발생했습니다.',
                icon: 'error',
            });
        }
    }

    const saveTelegramSettings = async () => {
        const request = await fetch(`${_API_URL}/user/telegram`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                userToken: _API_KEY,
                ...telegramSettings
            }),
        });
        const response = await request.json();

        if (response.success) {
            Swal.fire({
                title: '저장 성공',
                text: '텔레그램 설정을 저장했습니다.',
                icon: 'success',
            });
        } else {
            Swal.fire({
                title: '오류',
                text: '텔레그램 설정을 저장하는 중 오류가 발생했습니다.',
                icon: 'error',
            });
        }
    }

    const searchPattern = async () => {
        if (searchPatternForm.gameType === '') {
            alert('게임 종류를 선택하세요.');
            throw new Error('게임 종류를 선택하세요.');
        }
        if (searchPatternForm.pattern === '') {
            alert('서식을 입력하세요.');
            throw new Error('서식을 입력하세요.');
        }
        if (searchPatternForm.period === '') {
            alert('기간을 선택하세요.');
            throw new Error('기간을 선택하세요.');
        }

        const request = await fetch(`${_API_URL}/pattern/search`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                userToken: _API_KEY,
                ...searchPatternForm,
            }),
        });
        const response = await request.json();

        if (response.success) {
            setSearchPatternResult(response.result);
        } else {
            Swal.fire({
                title: '오류',
                text: '패턴 검색 중 오류가 발생했습니다.',
                icon: 'error',
            });
            throw new Error('패턴 검색 중 오류가 발생했습니다.');
        }
    }

    const socket = useRef<WebSocket | null>(null);
    useEffect(() => {
        const _socket = new WebSocket('wss://api.evostreams.net');
        _socket.onmessage = (e) => {
            const data = JSON.parse(e.data);
            console.log(data);

            if (data.type === 'gameState') {
                setCurrentGameRound(data.roundIds[1]);

                if (data.value.stage === 'Betting') {
                    const now = new Date(data.time);
                    now.setMilliseconds(now.getMilliseconds() + data.value.nextStageIn);
                    setNextGameTime(now);
                }
            }
        };

        socket.current = _socket;
    }, []);

    useInterval(() => {
        const now = new Date();
        const leftSeconds = Math.floor((nextGameTime.getTime() - now.getTime()) / 1000);

        if (leftSeconds < 0) {
            setGameLeftTime(-1);
        } else {
            setGameLeftTime(leftSeconds);
        }
    }, 100);

    useInterval(() => {
        getPattern(true).then();
        getHistory().then();
        getAutoEnabled(false).then();
        getStatistics().then();
    }, 3000);

    return (
        <div className="App">
            <div key="1" className={`bg-${_THEME === 'black' ? 'gray' : 'white'}-800 text-${_THEME === 'black' ? 'white' : 'black'}`}>
                <div className="flex flex-col sm:flex-row justify-between items-center p-4">
                    <div className="flex flex-col sm:flex-row items-center space-x-2" style={{ width: '260px' }}>
                        <Bot/>
                        <h1 className="text-xl font-bold">스피드파워볼 오토</h1>
                    </div>
                    <div className="flex flex-col sm:flex-row space-x-4 items-center mt-4 sm:mt-0">
                        <div>
                            <Badge>오늘 누적 베팅 : ₩{statistics.betAmountSum.toLocaleString()}</Badge>
                            <Badge>오늘 누적 승리 : ₩{statistics.winAmountSum.toLocaleString()}</Badge>
                        </div>
                        <div>
                            <Badge>오늘 손익 : ₩{(statistics.winAmountSum - statistics.betAmountSum).toLocaleString()}</Badge>
                            <Badge>오늘 베팅 횟수 : {statistics.betCount}회</Badge>
                        </div>
                        <div className="flex items-center space-x-2 mt-1 md:mt-0" style={{ width: '160px' }}>
                            <Switch id="auto-switch" onCheckedChange={() => updateAutoEnabled(!autoEnabled)}
                                    checked={autoEnabled}/>
                            <Label htmlFor="auto-switch" className={`text-${_THEME === 'black' ? 'white' : 'black'}`}>오토 사용</Label>
                        </div>
                        <Dialog open={isSearchDialogOpen} onOpenChange={(open) => setIsSearchDialogOpen(open)}>
                            <DialogTrigger><Button className="bg-blue-500 mb-1 mt-1 md:mb-0 md:mt-0"><Search className="h-4 w-4"/>&nbsp;&nbsp;서식
                                손익 검색</Button></DialogTrigger>
                            <DialogContent>
                                <DialogHeader>
                                    <DialogTitle>서식(패턴) 손익 검색</DialogTitle>
                                    <DialogDescription>
                                        <div className={"mb-1 mt-3"}>
                                            <Select value={searchPatternForm.gameType}
                                                    onValueChange={(value) => setSearchPatternForm({
                                                        ...searchPatternForm,
                                                        gameType: value,
                                                    })}>
                                                <SelectTrigger>
                                                    <SelectValue placeholder="게임 종류"/>
                                                </SelectTrigger>
                                                <SelectContent>
                                                    {Object.entries(_GAMES).map(([key, value]) => <SelectItem
                                                        value={key}>{value}</SelectItem>)}
                                                </SelectContent>
                                            </Select>
                                        </div>
                                        <div className={"mb-1"}>
                                            <Select value={searchPatternForm.period}
                                                    onValueChange={(value) => setSearchPatternForm({
                                                        ...searchPatternForm,
                                                        period: value,
                                                    })}>
                                                <SelectTrigger>
                                                    <SelectValue placeholder="검색 기간"/>
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectItem value="day">1일</SelectItem>
                                                    <SelectItem value="week">1주</SelectItem>
                                                    <SelectItem value="month">1개월</SelectItem>
                                                </SelectContent>
                                            </Select>
                                        </div>
                                        <div className={"mb-1"}>
                                            <Textarea placeholder="서식" value={searchPatternForm.pattern}
                                                      onChange={(e) => setSearchPatternForm({
                                                          ...searchPatternForm,
                                                          pattern: (e.target as HTMLTextAreaElement).value,
                                                      })}/>
                                        </div>
                                        <Button className={"w-full"} onClick={() => searchPattern()}>검색</Button>

                                        {searchPatternResult && (
                                            <Table className={"mt-5"}>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>승패</TableCell>
                                                        <TableCell>{searchPatternResult.targetGameCount.toLocaleString()}게임
                                                            중 {searchPatternResult.winCount.toLocaleString()}승 {searchPatternResult.loseCount.toLocaleString()}패
                                                            ({(searchPatternResult.winCount / (searchPatternResult.winCount + searchPatternResult.loseCount) * 100).toFixed(2)}%)</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>베팅</TableCell>
                                                        <TableCell>
                                                            베팅 ₩{searchPatternResult.betSum.toLocaleString()} / 당첨
                                                            ₩{searchPatternResult.winSum.toLocaleString()}<br/>
                                                            손익
                                                            ₩{(searchPatternResult.winSum - searchPatternResult.betSum).toLocaleString()}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>순간최대</TableCell>
                                                        <TableCell>
                                                            최대 손실
                                                            ₩{searchPatternResult.minBalance.toLocaleString()}<br/>
                                                            최대 수익 ₩{searchPatternResult.maxBalance.toLocaleString()}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        )}
                                    </DialogDescription>
                                </DialogHeader>
                            </DialogContent>
                        </Dialog>
                        <Dialog onOpenChange={(open) => open && loadTelegramSettings()}>
                            <DialogTrigger><Button className="bg-blue-500 mb-1 md:mb-0"><BellIcon
                                className="h-4 w-4"/>&nbsp;&nbsp;텔레그램 알림</Button></DialogTrigger>
                            <DialogContent>
                                <DialogHeader>
                                    <DialogTitle>텔레그램 알림 설정</DialogTitle>
                                    <DialogDescription>
                                        <BrowserView>
                                            <h2>1. 휴대전화의 카메라 앱을 켜고 아래 QR코드를 스캔하세요.</h2>
                                            <div className="mt-1"><img
                                                src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=https%3A%2F%2Ft.me%2Fspeedpowerball_auto_bot%3Fstart%3D${telegramToken}`}/>
                                            </div>
                                        </BrowserView>
                                        <MobileView>
                                            <h2>1. 아래 버튼을 눌러 텔레그램 봇을 등록하세요.</h2>
                                            <div className="mt-1"><Button
                                                onClick={() => window.open(`https://t.me/speedpowerball_auto_bot?start=${telegramToken}`)}>텔레그램
                                                봇 등록</Button></div>
                                        </MobileView>
                                        <br/>
                                        <h2>2. 알림을 받을 항목을 선택하세요.</h2>
                                        <div className="flex items-center space-x-2 mt-1">
                                            <Switch id="bet-alarm-switch"
                                                    onCheckedChange={(checked) => setTelegramSettings({
                                                        ...telegramSettings,
                                                        doUseBetAlert: checked
                                                    })} checked={telegramSettings.doUseBetAlert}/>
                                            <Label htmlFor="bet-alarm-switch">베팅 알림</Label>
                                        </div>
                                        <div className="flex items-center space-x-2 mt-1">
                                            <Switch id="bet-result-switch"
                                                    onCheckedChange={(checked) => setTelegramSettings({
                                                        ...telegramSettings,
                                                        doUseResultAlert: checked
                                                    })} checked={telegramSettings.doUseResultAlert}/>
                                            <Label htmlFor="bet-result-switch">베팅 결과 알림</Label>
                                        </div>
                                        <div className="flex items-center space-x-2 mt-1">
                                            <Switch id="bet-stop-switch"
                                                    onCheckedChange={(checked) => setTelegramSettings({
                                                        ...telegramSettings,
                                                        doUseStopAlert: checked
                                                    })} checked={telegramSettings.doUseStopAlert}/>
                                            <Label htmlFor="bet-stop-switch">베팅 중지 알림</Label>
                                        </div>
                                        <br/>
                                        <DialogClose asChild>
                                            <Button onClick={() => saveTelegramSettings()}>저장</Button>
                                        </DialogClose>
                                    </DialogDescription>
                                </DialogHeader>
                            </DialogContent>
                        </Dialog>
                        <Wallet/>&nbsp;₩{balance.toLocaleString()}
                    </div>
                </div>

                <div className="bg-red-500 text-center py-2 text-white">
                    <span className="text-2xl font-bold">{currentGameRound === -1 ? '...' : currentGameRound}회차</span>
                    &nbsp;
                    <span className="text-lg">{gameLeftTime === -1 ? '진행중' : `${gameLeftTime}초`}</span>
                </div>
                <div className="flex overflow-x-auto py-2 space-x-2 px-4"/>
                <div className={`grid grid-cols-1 gap-4 p-4`}>
                    <div>
                        <div className={`${_THEME === 'black' ? 'bg-gray-700' : 'bg-gray-300'} p-2`}>
                            <p>수익 : ₩{betAmounts.winAmount.toLocaleString()}</p>
                            <p>손실 : ₩{betAmounts.loseAmount.toLocaleString()}</p>
                            <p>손익 : ₩{(betAmounts.winAmount - betAmounts.loseAmount).toLocaleString()}</p>
                            <div className="grid w-full max-w-sm items-center gap-1.5 mt-1">
                                <Label htmlFor="email">수익 한도 (수익이 이 금액에 도달하면 베팅이 중단됩니다)</Label>
                                <Input type={"text"} value={limits.winLimit?.toLocaleString()} onChange={(e) => setLimits({ ...limits, winLimit: parseInt((e.target as HTMLInputElement).value.replace(/,/g, '') === '' ? '0' : (e.target as HTMLInputElement).value.replace(/,/g, '')), })} id={"txtWinLimit"} className={`text-black`} />
                            </div>
                            <div className="grid w-full max-w-sm items-center gap-1.5 mt-2 mb-1">
                                <Label htmlFor="email">손실 한도 (손실이 이 금액에 도달하면 베팅이 중단됩니다)</Label>
                                <Input type={"text"} value={limits.loseLimit?.toLocaleString()} onChange={(e) => setLimits({ ...limits, loseLimit: parseInt((e.target as HTMLInputElement).value.replace(/,/g, '') === '' ? '0' : (e.target as HTMLInputElement).value.replace(/,/g, '')), })} id={"txtLoseLimit"} className={`text-black`} />
                            </div>
                            <Button
                                onClick={() => updateLimits(limits.winLimit ?? 0, limits.loseLimit ?? 0)}>적용</Button>
                        </div>
                    </div>
                </div>
                <div className={`grid grid-cols-1 md:grid-cols-4 gap-4 p-4`}>
                    {Object.entries(_GAMES).map(([gameId, gameName]) => (
                        <div>
                            <div className={`${_THEME === 'black' ? 'bg-gray-700' : 'bg-gray-300'} p-2`}>
                                <div className="flex justify-between items-center">
                                    <span>{gameName}</span>
                                </div>
                            </div>
                            <div className={`${_THEME === 'black' ? 'bg-gray-700' : 'bg-gray-300'} p-2 mt-4`}>
                                <div className="flex justify-between items-center">
                                    <span>베팅패턴</span>
                                    <ChevronDownIcon className="h-4 w-4 self-center"/>
                                </div>
                                <div className={"mt-2"}>
                                    <table style={{width: '100%', marginTop: '-5px'}}>
                                        <thead>
                                        <tr style={{textAlign: 'left', fontSize: '0.875rem', fontWeight: '500'}}>
                                        <th>찾을 패턴</th>
                                            <th style={{width: '65px'}}>베팅</th>
                                            <th style={{width: '28px'}}></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {Array((patterns.find((pattern) => pattern.game.id === gameId)?.pattern ?? '').split('\n')[0].split(':').length - 1).fill(0).map((_, i) => (
                                            <tr style={{height: '35px'}}>
                                                <td>
                                                    {(patterns.find((pattern) => pattern.game.id === gameId)?.pattern ?? '').split(':')[i + 1].split('-')[0].split('').map((item) => item === '1' ? (
                                                        <BlueItem>{(gameId === 'POWERBALL_UNOVER' || gameId === 'REGULAR_UNOVER') ? '언더' : '홀'}</BlueItem>
                                                    ) : (
                                                        <RedItem>{(gameId === 'POWERBALL_UNOVER' || gameId === 'REGULAR_UNOVER') ? '오버' : '짝'}</RedItem>
                                                    ))}
                                                </td>
                                                <td>
                                                    {(patterns.find((pattern) => pattern.game.id === gameId)?.pattern ?? '').split('\n')[0].split(':')[i + 1].split('-')[1] === '1' ? (
                                                        <BlueItem>{(gameId === 'POWERBALL_UNOVER' || gameId === 'REGULAR_UNOVER') ? '언더' : '홀'}</BlueItem>
                                                    ) : (
                                                        <RedItem>{(gameId === 'POWERBALL_UNOVER' || gameId === 'REGULAR_UNOVER') ? '오버' : '짝'}</RedItem>
                                                    )}
                                                </td>
                                                <td>
                                                    <button style={{height: '35px'}}
                                                            onClick={() => removePatternRow((patterns.find((pattern) => pattern.game.id === gameId)?.id ?? 0), i)}>
                                                        <X/>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                        <tr style={{
                                            textAlign: 'left',
                                            fontSize: '0.875rem',
                                            fontWeight: '500',
                                            height: '30px'
                                        }}>
                                            <td colSpan={3}>추가할 패턴</td>
                                        </tr>
                                        <tr style={{height: '35px'}}>
                                            <td>
                                                {(patterns.find((pattern) => pattern.game.id === gameId)?.pendingPattern ?? '').split('-')[0].split('').map((item, index) => item === '1' ? (
                                                    <BlueItem useDelete={true}
                                                              onClick={() => removePendingItem((patterns.find((pattern) => pattern.game.id === gameId)?.id ?? 0), index)}>{(gameId === 'POWERBALL_UNOVER' || gameId === 'REGULAR_UNOVER') ? '언더' : '홀'}</BlueItem>
                                                ) : (
                                                    <RedItem useDelete={true}
                                                             onClick={() => removePendingItem((patterns.find((pattern) => pattern.game.id === gameId)?.id ?? 0), index)}>{(gameId === 'POWERBALL_UNOVER' || gameId === 'REGULAR_UNOVER') ? '오버' : '짝'}</RedItem>
                                                ))}
                                            </td>
                                            <td>
                                                {(patterns.find((pattern) => pattern.game.id === gameId)?.pendingPattern ?? '').split('-')[1] === '1' ? (
                                                    <BlueItem>{(gameId === 'POWERBALL_UNOVER' || gameId === 'REGULAR_UNOVER') ? '언더' : '홀'}</BlueItem>
                                                ) : (patterns.find((pattern) => pattern.game.id === gameId)?.pendingPattern ?? '').split('-')[1] === '2' ? (
                                                    <RedItem>{(gameId === 'POWERBALL_UNOVER' || gameId === 'REGULAR_UNOVER') ? '오버' : '짝'}</RedItem>
                                                ) : null}
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr style={{height: '35px'}}>
                                            <td>
                                                <BlueItem
                                                    onClick={() => addPendingItem(patterns.find((pattern) => pattern.game.id === gameId)?.id ?? 0, '1')}>{(gameId === 'POWERBALL_UNOVER' || gameId === 'REGULAR_UNOVER') ? '언더' : '홀'}</BlueItem>
                                                <RedItem
                                                    onClick={() => addPendingItem(patterns.find((pattern) => pattern.game.id === gameId)?.id ?? 0, '2')}>{(gameId === 'POWERBALL_UNOVER' || gameId === 'REGULAR_UNOVER') ? '오버' : '짝'}</RedItem>
                                            </td>
                                            <td>
                                                <BlueItem
                                                    onClick={() => setPendingTarget(patterns.find((pattern) => pattern.game.id === gameId)?.id ?? 0, '1')}>{(gameId === 'POWERBALL_UNOVER' || gameId === 'REGULAR_UNOVER') ? '언더' : '홀'}</BlueItem>
                                                <RedItem
                                                    onClick={() => setPendingTarget(patterns.find((pattern) => pattern.game.id === gameId)?.id ?? 0, '2')}>{(gameId === 'POWERBALL_UNOVER' || gameId === 'REGULAR_UNOVER') ? '오버' : '짝'}</RedItem>
                                            </td>
                                            <td>
                                                <button style={{height: '35px'}}
                                                        onClick={() => addPending(patterns.find((pattern) => pattern.game.id === gameId)?.id ?? 0)}>
                                                    <Plus/></button>
                                            </td>
                                        </tr>
                                        <tr></tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className={`${_THEME === 'black' ? 'bg-gray-700' : 'bg-gray-300'} p-2 mt-4`}>
                                <div className="flex justify-between items-center">
                                    <span>베팅금액</span>
                                    <ChevronDownIcon className="h-4 w-4 self-center"/>
                                </div>
                                <div className={"mt-2"}>
                                    {Array(11).fill(0).map((_, i) => (
                                        <Input disabled={autoEnabled} type="text"
                                               style={i === patterns.find((pattern) => pattern.game.id === gameId)?.betRow ? {borderColor: 'black'} : {}}
                                               placeholder={`${i + 1}단계 베팅 금액`}
                                               value={parseInt((patterns.find((pattern) => pattern.game.id === gameId)?.pattern ?? '').split('\n')[i]?.split(':')[0] ?? '0').toLocaleString()}
                                               onChange={(e) => updateBetAmount(patterns.find((pattern) => pattern.game.id === gameId)?.id ?? 0, i, (e.target as HTMLInputElement).value.replace(/,/g, ''))}
                                               className={`mb-1 text-black`} />
                                    ))}
                                </div>
                            </div>
                            <div className={`${_THEME === 'black' ? 'bg-gray-700' : 'bg-gray-300'} p-2 mt-4`}>
                                <input type={"hidden"} id={`Pattern_${patterns.find((pattern) => pattern.game.id === gameId)?.id ?? 0}`}
                                       className={"h-64"}
                                       value={patterns.find(pattern => pattern.game.id === gameId)?.pattern ?? ''}/>
                                <Button
                                    className={"w-100"}
                                    onClick={(e) => {
                                        setIsSearchDialogOpen(true);
                                        setSearchPatternForm({
                                            gameType: gameId,
                                            period: 'day',
                                            pattern: (document.querySelector(`#Pattern_${patterns.find((pattern) => pattern.game.id === gameId)?.id ?? 0}`) as HTMLTextAreaElement).value,
                                        });
                                    }}>패턴 손익 검색</Button>
                                <Button
                                    className={"w-100 ml-3"}
                                    onClick={() => editPattern(patterns.find((pattern) => pattern.game.id === gameId)?.id ?? 0, (document.querySelector(`#Pattern_${patterns.find((pattern) => pattern.game.id === gameId)?.id ?? 0}`) as HTMLTextAreaElement).value)}
                                    disabled={autoEnabled}>수정</Button>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={`grid grid-cols-1 gap-4 p-4`}>
                    <div className={`${_THEME === 'black' ? 'bg-gray-700' : 'bg-gray-300'} p-2`}>
                        <div className="overflow-auto">
                            <table className="min-w-full">
                                <thead>
                                <tr>
                                    <th className="text-left p-2">시간</th>
                                    <th className="text-left p-2">회차</th>
                                    <th className="text-left p-2">베팅항목</th>
                                    <th className="text-left p-2">베팅금액</th>
                                    <th className="text-left p-2">결과</th>
                                    <th className="text-left p-2">당첨금</th>
                                </tr>
                                </thead>
                                <tbody>
                                {betHistory?.map((bet) => (
                                    <tr>
                                        <td className="p-2">{formatDate(new Date(bet.created))}</td>
                                        <td className="p-2">{bet.roundId}회차</td>
                                        <td className="p-2">{bet.item === 'PowerUnder' ? '파워볼 언더' : bet.item === 'PowerOver' ? '파워볼 오버' : bet.item === 'PowerOdd' ? '파워볼 홀' : bet.item === 'PowerEven' ? '파워볼 짝' : bet.item === 'RegularUnder' ? '일반볼 언더' : bet.item === 'RegularOver' ? '일반볼 오버' : bet.item === 'RegularOdd' ? '일반볼 홀' : '일반볼 짝'}</td>
                                        <td className="p-2">₩{bet.amount.toLocaleString()}</td>
                                        <td className="p-2">{bet.result === 1 ? '당첨' : bet.result === 0 ? '대기중' : '낙첨'}</td>
                                        <td className="p-2">₩{bet.winAmount.toLocaleString()}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
